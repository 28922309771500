<template>
  <div>
    <p class="font-size-16 my-4 font-weight-500 doc-page-layout-page-title">
      Page {{ pageNo }}
    </p>
    <div
      class="doc-page-layout mb-6"
      :id="`doc-page-layout-${pageNo}`"
      :data-page-no="pageNo"
      v-on:drop="dropEvent($event, pageNo)"
      v-on:dragover="allowDropEvent($event)"
    >
      <v-img contain :src="image" :lazy-src="$defaultImage"></v-img>
      <div
        v-for="(element, elementId) in pageElements(pageNo)"
        :key="elementId"
        :data-page-no="pageNo"
        :data-type="element.type"
        :data-left="element.style.x"
        :data-top="element.style.y"
        :data-width="element.style.w"
        :class="`doc-page-layout-${pageNo}-drag-element`"
        :id="`doc-page-layout-element-${element.uuid}`"
        class="doc-page-element"
        :data-uuid="element.uuid"
        v-bind:style="{
          position: 'absolute',
          left: element.style.x + 'px',
          top: element.style.y + 'px',
          width: element.style.w + 'px',
        }"
      >
        <div :class="`element-actions element-action-${element.type} mb-3`">
          <v-layout>
            <v-flex md6 class="font-weight-500">
              <v-menu offset-y bottom max-height="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    content="Who needs to fill out this?"
                    v-tippy
                    rounded
                    v-bind="attrs"
                    v-on="on"
                    class="text-truncate d-inline-grid"
                  >
                    <template
                      v-if="element.uuid && elementRecipient[element.uuid]"
                    >
                      {{ getAssignedRecipient(elementRecipient[element.uuid]) }}
                    </template>
                    <template v-else>
                      <v-icon left>mdi-account-plus</v-icon>
                      Assign
                    </template>
                  </v-btn>
                </template>

                <v-list two-line>
                  <v-list-item-group
                    v-model="elementRecipient[element.uuid]"
                    v-on:change="updateElements()"
                    color="cyan"
                  >
                    <v-list-item
                      v-for="(row, ind) in recipients"
                      :key="ind"
                      :value="row.id"
                    >
                      <v-list-item-avatar
                        class="m-0 my-1 mr-3 page-element-user-avatar"
                      >
                        {{ pageElementUserAvatar(row) }}
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <template v-if="row.first_name">
                            {{ row.first_name }} {{ row.last_name }}
                          </template>
                          <template v-else>{{ row.email }}</template>
                        </v-list-item-title>
                        <v-list-item-subtitle>{{
                          row.email
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-flex>
            <v-flex md2>
              <v-btn
                small
                v-on:click="duplicateElement(element.uuid)"
                content="Duplicate"
                v-tippy
                rounded
              >
                <v-icon>mdi-content-duplicate</v-icon>
              </v-btn>
            </v-flex>
            <v-flex md2>
              <v-btn
                small
                v-on:click="updateProperty(element.uuid)"
                content="Property"
                v-tippy
                rounded
              >
                <v-icon>mdi-tune</v-icon>
              </v-btn>
            </v-flex>
            <v-flex md2>
              <v-btn
                small
                v-on:click="deleteElement(element.uuid)"
                content="Delete"
                v-tippy
                rounded
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </div>
        <template v-if="element.type == 'checkbox'">
          <div
            class="doc-page-element-detail"
            :class="`doc-page-element-${element.type}`"
            :id="`doc-page-layout-${pageNo}-drag-element-${elementId}`"
          >
            <v-icon>{{ element.icon }}</v-icon>
          </div>
        </template>
        <template v-else-if="element.type == 'crossbox'">
          <div
            class="doc-page-element-detail"
            :class="`doc-page-element-${element.type}`"
            :id="`doc-page-layout-${pageNo}-drag-element-${elementId}`"
          >
            <v-icon>{{ element.icon }}</v-icon>
          </div>
        </template>
        <template v-else>
          <v-layout
            class="doc-page-element-detail"
            :class="`doc-page-element-${element.type}`"
            :id="`doc-page-layout-${pageNo}-drag-element-${elementId}`"
          >
            <v-flex md2 class="mr-4 my-auto"
              ><v-icon class="mx-2">{{ element.icon }}</v-icon></v-flex
            >
            <v-flex md10 class="my-auto"
              ><p class="m-0 font-size-16 font-weight-500">
                {{ element.title }}
              </p></v-flex
            >
          </v-layout>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
// import interact from "interactjs";
import { v4 as uuidv4 } from "uuid";
import { DocumentEventBus } from "@/core/lib/document.lib";

export default {
  props: {
    pageNo: {
      type: Number,
      default: 0,
    },
    docEle: {
      type: Array,
    },
    recipients: {
      type: Array,
    },
    image: {
      type: String,
    },
  },
  watch: {
    docEle: {
      deep: true,
      handler() {
        this._initPage();
      },
    },
  },
  data() {
    return {
      actionMenu: {},
      elementRecipient: {},
      elementProperty: {},
      deletedElements: [],
    };
  },
  methods: {
    _initPage() {
      this.elementRecipient = {};
      this.elementProperty = {};
      for (let i = 0; i < this.docEle.length; i++) {
        if (this.docEle[i].user) {
          this.elementRecipient[this.docEle[i].uuid] = this.docEle[i].user;
        }
        if (this.docEle[i].property) {
          this.elementProperty[this.docEle[i].uuid] = this.docEle[i].property;
        }
      }
    },
    getAssignedRecipient(assigned) {
      const result = [];
      const assignedUser = this.lodash.find(this.recipients, { id: assigned });
      if (assignedUser) {
        if (assignedUser.first_name || assignedUser.last_name) {
          if (assignedUser.first_name) {
            result.push(assignedUser.first_name);
          }
          if (assignedUser.last_name) {
            result.push(assignedUser.last_name);
          }
        } else if (assignedUser.email) {
          result.push(assignedUser.email);
        }
      }
      return result.join(" ");
    },
    updateProperty(elementUUID) {
      const index = this.lodash.findIndex(this.docEle, { uuid: elementUUID });
      if (index >= 0) {
        const element = this.docEle[index];
        const user = this.elementRecipient[elementUUID];
        const property = this.elementProperty[elementUUID];
        this.$emit("update:property", { element, user, property });
      }
    },
    submitProperty({ uuid, user, property }) {
      this.$nextTick(() => {
        this.elementRecipient[uuid] = user;
        this.elementProperty[uuid] = property;
        this.updateElements();
      });
    },
    duplicateElement(elementUUID) {
      const element = document.getElementById(
        `doc-page-layout-element-${elementUUID}`
      );
      if (element) {
        const dataLeft = this.lodash.toNumber(
          element.getAttribute("data-left")
        );
        const dataPage = this.lodash.toSafeInteger(
          element.getAttribute("data-page-no")
        );
        const dataTop = this.lodash.toNumber(element.getAttribute("data-top"));
        const dataWidth = this.lodash.toNumber(
          element.getAttribute("data-width")
        );
        const dataType = element.getAttribute("data-type");
        const newUUID = uuidv4();

        const docEle = this.lodash.find(this.docEle, { type: dataType });

        if (docEle && !this.lodash.isEmpty(docEle)) {
          this.docEle.push({
            icon: docEle.icon,
            left: dataLeft - 10,
            page: dataPage,
            style: new Object({
              w: dataWidth,
              x: dataLeft - 10,
              y: dataTop - 10,
            }),
            title: docEle.title,
            top: dataTop - 10,
            type: docEle.type,
            uuid: newUUID,
          });

          this.$nextTick(() => {
            this.updateElements();
          });
        }
      }
    },
    deleteElement(elementUUID) {
      const index = this.lodash.findIndex(this.docEle, { uuid: elementUUID });
      if (index >= 0) {
        console.log({ deletedElements: this.deletedElements });
        this.deletedElements.push(elementUUID);
        this.docEle.splice(index, 1);
        this.$nextTick(() => {
          this.updateElements();
        });
      }
    },
    pageElements(page) {
      return this.lodash.filter(this.docEle, { page }) || [];
    },
    pageElementUserAvatar(row) {
      if (row.first_name) {
        const first_name = row.first_name
          ? row.first_name.charAt(0).toUpperCase()
          : "";
        const last_name = row.last_name
          ? row.last_name.charAt(0).toUpperCase()
          : "";
        return first_name + (last_name ? " " + last_name : "");
      } else {
        const email = row.email ? row.email.charAt(0).toUpperCase() : "";
        return email;
      }
    },
    allowDropEvent(evt) {
      evt.preventDefault();
    },
    dropEvent(evt, page) {
      const dragItem = JSON.parse(evt.dataTransfer.getData("content"));

      dragItem.page = page;
      dragItem.top = this.lodash.toNumber(evt.offsetY);
      dragItem.left = this.lodash.toNumber(evt.offsetX);

      dragItem.style = new Object({
        x: evt.offsetX,
        y: evt.offsetY,
        w: 200,
      });

      dragItem.uuid = uuidv4();

      this.docEle.push(dragItem);

      this.$nextTick(() => {
        this.updateElements();
      });
    },
    _initDragDrop() {
      //
    },
    dragMoveListener(event) {
      const target = event.target;
      // keep the dragged position in the data-x/data-y attributes
      const x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
      const y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

      // translate the element
      target.style.transform = "translate(" + x + "px, " + y + "px)";

      // update the position attributes
      target.setAttribute("data-x", x);
      target.setAttribute("data-y", y);
    },
    updateElements() {
      const elements = [];
      const docPageElements =
        document.getElementsByClassName("doc-page-element");
      if (docPageElements && docPageElements.length) {
        for (let i = 0; i < docPageElements.length; i++) {
          const element = docPageElements[i];

          if (element) {
            const dataLeft = this.lodash.toNumber(
              element.getAttribute("data-left")
            );
            const dataPage = this.lodash.toSafeInteger(
              element.getAttribute("data-page-no")
            );
            const dataTop = this.lodash.toNumber(
              element.getAttribute("data-top")
            );
            const dataType = element.getAttribute("data-type");
            const dataUUID = element.getAttribute("data-uuid");

            elements.push({
              left: dataLeft,
              page: dataPage,
              top: dataTop,
              type: dataType,
              uuid: dataUUID,
              user: this.elementRecipient[dataUUID],
              placeholder: this.elementProperty[dataUUID]
                ? this.elementProperty[dataUUID].placeholder
                : null,
              required: this.elementProperty[dataUUID]
                ? this.elementProperty[dataUUID].required
                : false,
            });
          }
        }
      }
      this.$nextTick(() => {
        console.log({ deletedElements: this.deletedElements });
        DocumentEventBus.$emit("update:page-elements", {
          elements,
          deletedElements: this.deletedElements,
        });
      });
    },
  },
  beforeDestroy() {
    const pageNo = this.lodash.toSafeInteger(this.pageNo);

    DocumentEventBus.$off(`duplicate:page-element-${pageNo}`);

    DocumentEventBus.$off(`delete:page-element-${pageNo}`);

    DocumentEventBus.$off(`submit:property-${pageNo}`);
  },
  mounted() {
    this._initPage();
    this._initDragDrop();

    const pageNo = this.lodash.toSafeInteger(this.pageNo);

    DocumentEventBus.$on(`duplicate:page-element-${pageNo}`, (uuid) => {
      this.duplicateElement(uuid);
    });

    DocumentEventBus.$on(`delete:page-element-${pageNo}`, (uuid) => {
      this.deleteElement(uuid);
    });

    DocumentEventBus.$on(`submit:property-${pageNo}`, (param) => {
      this.submitProperty(param);
    });
  },
};
</script>
